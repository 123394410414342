<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Ranking</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Ranking</li>
            </ul>
        </div>
    </div>
</div>

<section class="cart-area ptb-100" *ngIf="!loading">
    <div class="container">
        <div class="cart-wrap">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <h2>Buscar por categoría:</h2>
                        <select class="form-control" [(ngModel)]="searchCategoria" (change)="changeSearchCategoria()">
                            <option value="0">Todas</option>
                            <option *ngFor="let categoria of categorias">{{categoria.nombre}} - {{categoria.modo}} - {{categoria.sexo}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <h2>Buscar por competidor:</h2>
                        <input (keyup)="changeSearchCorredor()" [(ngModel)]="searchCorredor" type="text" class="form-control" placeholder="Competidor">
                    </div>
                </div>

            </div>
            <br>

            <ng-container *ngFor="let categoria of resultadosFilter['categorias']">
                <h2>Categoría {{categoria['nombre']}} - {{categoria['modo']}} - {{categoria['sexo']}}</h2>
                <table class="table" style="table-layout: initial !important">
                    <thead class="thead">
                    <tr>
                        <th class="table-head" scope="col">Lugar</th>
                        <th class="table-head" scope="col">Competidor</th>
                        <th class="table-head" scope="col" *ngFor="let item of [].constructor(resultadosFilter['cantidad_fechas']); let i = index">Fecha {{i+1}}</th>
                        <th class="table-head" scope="col">Puntaje total</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let corredor of filterCorredores(categoria['corredores']); let i = index">
                        <td>{{corredor['lugar']}}</td>
                        <td>{{corredor['nombre']}} {{corredor['apellido']}}<br><span style="font-size: 12px; font-weight: bold; color: grey">{{corredor['team']}}</span></td>
                        <td *ngFor="let item of [].constructor(resultadosFilter['cantidad_fechas']); let i = index"> {{getPuntajeFecha(corredor, i+1)}} </td>
                        <td>{{corredor['puntaje_total']}}
                            <span *ngIf="corredor['lugar'] === 1">🥇</span>
                            <span *ngIf="corredor['lugar'] === 2">🥈</span>
                            <span *ngIf="corredor['lugar'] === 3">🥉</span>
                            </td>
                    </tr>
                    </tbody>
                </table>
                <br>
                <br>
                <br>
            </ng-container>

        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>

