import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {EventoService} from '../../../services/evento.service';
import {take, takeUntil} from 'rxjs/operators';
import {MathHelper} from '../../../shared/math_helper';
import {GoogleAnalytics} from '../../../shared/google-analytics';
import { ActivatedRoute } from '@angular/router';
import { CalificacionService } from 'src/app/services/calificacion.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public eventos: any[] = [];
    public eventosFiltro: any[] = [];

    @ViewChild('todos') todos: ElementRef;
    @ViewChild('realizados') realizados: ElementRef;
    // @ViewChild('suspendidos') suspendidos: ElementRef;
    @ViewChild('por_realizar') porRealizar: ElementRef;
    @ViewChild('ciclismo') ciclismo: ElementRef;
    @ViewChild('running') running: ElementRef;

    public filtro = 'TODAS';

    query = '';


  constructor(
      private eventoService: EventoService,
      private mathHelper: MathHelper,
      private googleAnalytics: GoogleAnalytics,
      private activatedroute: ActivatedRoute,
      private calificacionService: CalificacionService,

  ) { }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('eventos', 'exitoso', '', 'click', 0);

        this.activatedroute.queryParams.subscribe(params => {
            if (params.query !== undefined) {
              this.query = params.query;
            }
          });


        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    getDataDefault() {
        this.loading = true;
        const obtenerEventos = this.eventoService.obtenerEventosVisibles(this.query);

        const petitions = forkJoin([obtenerEventos]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventos = [];
                    res[0].forEach((element: any) => {
                        this.eventos.push(element);
                    });

                    this.eventosFiltro = this.eventos;
                    this.filtrarEventos('TODAS');

                    this.getDataCalificaciones();
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    getDataCalificaciones() {
    
        const obtenerCalificaciones = this.calificacionService.obtenerCalificacionesPorOrganizador(this.eventos.map(item => item.usuario.id).join(','));

        const petitions = forkJoin([obtenerCalificaciones]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventos.forEach((evento) => {
                        evento.calificacion = res[0].find((calificacion) => calificacion.id === evento.usuario.id);
                    });

                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    filtrarEventos(estado: string){
        this.filtro = estado;

        switch (estado){
            case 'TODAS':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS'|| evento.estado === 'INSCRIPCIONES_EXTERNAS').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                // this.eventosFiltro = this.eventosFiltro.concat(this.eventos.filter((evento) => evento.estado !== 'POR_REALIZAR' && evento.estado !== 'PROXIMAMENTE' && evento.estado !== 'INSCRIPCIONES_CERRADAS'|| evento.estado !== 'INSCRIPCIONES_EXTERNAS').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1)));
                break;
            case 'POR_REALIZAR':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS'|| evento.estado === 'INSCRIPCIONES_EXTERNAS');
                this.eventosFiltro = this.eventosFiltro.sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;
            case 'REALIZADO':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            default:
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;

        }


    }

    obtenerMenorPrecio(evento: any){
        return this.mathHelper.obtenerMenor(evento.categorias, 'valor');
    }

}
