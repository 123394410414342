<div class="row" >
    <ng-container *ngFor="let evento of eventosFiltro" >
    <div class="col-sm-6 col-lg-4" [ngClass]="[evento.estado]" data-aos="fade-up-right"  >
        <div class="collection-item">
            <div *ngIf="obtenerDias(evento) >0 || obtenerHoras(evento) > 0" class="time-item con-crono" >
                <span > QUEDAN &nbsp; {{obtenerDias(evento)}} DÍA(S) {{obtenerHoras(evento)}} HR(S)</span>
            </div>
            <div *ngIf="!(obtenerDias(evento) >0 || obtenerHoras(evento) > 0)" class="time-item con-crono" >
                <span > EVENTO FINALIZADO</span>
            </div>
            <div class="collection-top">
                <img  [src]="evento.url_foto" alt="Collection">
                <!-- <img style="border-radius: 10px 10px 0px 0px;" *ngIf="!(obtenerDias(evento) >0 && obtenerHoras(evento) > 0)" [src]="evento.url_foto" alt="Collection"> -->
               

                <ul>
                    <a class="detalles" routerLink="/evento" [queryParams]="{ id: evento.id}">Descripción</a>
                </ul>
                <div class="inscritos" *ngIf="evento.publicar_inscritos === 'PUBLICAR' && evento.estado !== 'REALIZADO'">
                    <a  routerLink="/inscripciones" [queryParams]="{ id: evento.id}"><i class='bx bxs-user-plus'></i> Inscritos</a>
                </div>

                <div class="inscritos" *ngIf="evento.estado === 'REALIZADO'">
                    <a  routerLink="/calificaciones" [queryParams]="{ id: evento.id}"><i class='bx bxs-star'></i> Calificaciones</a>
                </div>

                    <div class="add-cart">
                    <a *ngIf="evento.estado === 'POR_REALIZAR' && evento.cupos > evento.cantidad_inscritos" routerLink="/inscripcion" [queryParams]="{ id: evento.id}"><i class='bx bxs-cart'></i> Inscribirse</a>
                    <a *ngIf="evento.estado === 'SOLICITUD_INSCRIPCIONES' && evento.cupos > evento.cantidad_inscritos" routerLink="/inscripcion" [queryParams]="{ id: evento.id}"><i class='bx bxs-cart'></i> Inscribirse</a>
                    <a *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'ACTIVADO'" class="cmn-btn" routerLink="/resultados" [queryParams]="{ id: evento.id}"><i class='bx bxs-timer'></i> Resultados</a>
                    <a *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'DESACTIVADO'" ><i class='bx bxs-minus-circle'></i> Finalizado</a>
                    <a *ngIf="evento.cupos === evento.cantidad_inscritos && evento.estado === 'POR_REALIZAR'" ><span class="red"><i class='bx bxs-minus-circle'></i> Cupos agotados</span></a>
                    <a *ngIf="evento.estado === 'PROXIMAMENTE'"> <span class="green"><i class='bx bxs-calendar'></i> Próximamente</span></a>
                    <a *ngIf="evento.estado === 'SUSPENDIDO'"> <span class="red"><i class='bx bxs-error'></i> Suspendido</span></a>
                    <a *ngIf="evento.estado === 'INSCRIPCIONES_CERRADAS'"><span class="red"><i class='bx bxs-minus-circle'></i>  Inscripciones cerradas</span></a>
                    <a *ngIf="evento.estado === 'INSCRIPCIONES_EXTERNAS'"><span class="red"><i class='bx bxs-minus-circle'></i>  Inscripciones externas</span></a>

                </div>
            </div>

            <div class="collection-bottom">
                <div data-aos="flip-up" data-aos-duration="1000" class="platform-item preventa-disponible" *ngIf="evento.mostrar_preventa === 'ACTIVADO' && evento.estado === 'POR_REALIZAR' && evento.cupos > evento.cantidad_inscritos">
                    <span><a routerLink="/inscripcion" [queryParams]="{ id: evento.id}">PREVENTA DISPONIBLE</a></span>
                </div>
                <div data-aos="flip-up" data-aos-duration="1000" class="platform-item preventa-disponible" *ngIf="evento.estado === 'PROXIMAMENTE'">
                    <span>PRÓXIMAMENTE</span>
                </div>
                <div data-aos="flip-up" data-aos-duration="1000" class="platform-item solicitud-inscripcion" *ngIf="evento.estado === 'SOLICITUD_INSCRIPCIONES'">
                    <span>SOLICITUD DE INSCRIPCIÓN</span>
                </div>
                <h3 style="text-align: center" class="titulo">{{evento.nombre}}</h3>
                <span><strong>Lugar: </strong><span class="lugar">{{evento.lugar}}</span></span><br>
                <span *ngIf="evento.url_strava !== null"><strong>Strava: </strong><a [href]="evento.url_strava" target="_blank">Ver ruta</a></span>

                <ul>
                    <li data-aos="zoom-in" data-aos-duration="800"><span class="from">
                        
                        <!-- Desde ${{obtenerMenorPrecio(evento)}} -->
                    </span></li>
                    <li>
                        <div class="number">
                            <ng-container class="fecha_evento">{{evento.fecha_evento | date : 'dd-MM-yyyy' : 'UTC'}}</ng-container><br>
                            <!-- <span data-aos="flip-left" data-aos-duration="1000" style="background-color: green; float: right; border-radius: 30px">
                                <span style="width: fit-content;font-size: 13px;margin: 0 5px 5px 5px;color: white;" *ngIf="evento.estado === 'PROXIMAMENTE'"> PRÓXIMAMENTE</span>
                            </span> -->
                        </div>
                    </li>

                </ul>
                <hr>
                <div style="text-align: center;">
                    <app-estrellas-calificacion (click)="irACalificar(evento)" *ngIf="evento.calificacion !== undefined" [edit]="false" [small]="true" [initValue]="evento.calificacion.calificacion"></app-estrellas-calificacion>
                    <br *ngIf="evento.calificacion !== undefined">
                    <span style="font-size: 13px">Organiza: {{evento.usuario.firstName}} {{evento.usuario.lastName}}</span>
                </div>

            </div>
            <div class="platform-item con-crono" *ngIf="evento.servicio_cronometraje === 'ACTIVADO' && evento.estado !== 'REALIZADO'" >
                <span > ⏱️ CON CRONOMETRAJE BIKERACE</span>
            </div>
            <div class="platform-item calificar" *ngIf="evento.estado === 'REALIZADO'" (click)="irACalificar(evento)">
                <span > Calificar evento</span>
            </div>
        </div>
    </div>
</ng-container>
</div>
