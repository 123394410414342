<div class="shimmer">
    <div class="shimmer-rect shimmer-pulse" [style.width]="'50%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'50%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'50%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
    <br>
    <div class="shimmer-rect shimmer-pulse" [style.width]="'100%'" [style.height.px]="'100px'"></div>
</div>
