import { Component } from '@angular/core';

@Component({
  selector: 'app-shimmer-small',
  templateUrl: './shimmer-small.component.html',
  styleUrls: ['./shimmer-small.component.scss']
})
export class ShimmerSmallComponent {

}
