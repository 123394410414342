<app-navbar-style-three></app-navbar-style-three>
<!-- 
<app-banner-custom *ngIf="!loading" [urlBanner]="evento.url_banner" [eventName]="evento.nombre" routerOne="/"
    navigationOne="Inicio" [navigationTwo]="evento.nombre"></app-banner-custom> -->

    <div *ngIf="!loading" class="page-title-area banner xl" [style.background-image]="sanitizerUrl(evento.url_banner)">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                        <p><img *ngIf="evento.usuario.photoProfile !== null" style="margin-bottom: initial;
                            width: 45px;
                            height: 45px;
                            border: 2px solid #ffbe00;
                            margin-right: 10px;
                            border-radius: 21px;
                            object-fit: cover;" [src]="evento.usuario.photoProfile" />
                        <img *ngIf="evento.usuario.photoProfile === null" style="margin-bottom: initial;
                            width: 45px;
                            height: 45px;
                            border: 2px solid #ffbe00;
                            margin-right: 10px;
                            border-radius: 21px;
                            object-fit: cover;"
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/5778454.png?alt=media&token=3245a542-d833-4345-ab80-6835d629cbf9" />
                        {{evento.usuario.firstName}} {{evento.usuario.lastName}} presenta</p>
                        <h1 class="title">{{evento.nombre}}
                        </h1>
                        
                        <div class="button">
                            <a style="width: 50%;"
                            *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'ACTIVADO'"
                            class="cmn-btn" routerLink="/resultados" [queryParams]="{ id: evento.id}">Resultados</a>
                        <a style="width: 50%;"
                            *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'DESACTIVADO'"
                            routerLink="/calificar" class="cmn-btn"> Calificar</a>
    
                        <a style="width: 50%;"
                            *ngIf="evento.estado === 'POR_REALIZAR' && evento.cupos > evento.cantidad_inscritos"
                            class="cmn-btn" routerLink="/inscripcion"
                            [queryParams]="{ id: evento.id}">Inscribirse</a>

                            <a style="width: 50%;"
                            *ngIf="evento.estado === 'SOLICITUD_INSCRIPCIONES' && evento.cupos > evento.cantidad_inscritos"
                            class="cmn-btn" routerLink="/inscripcion"
                            [queryParams]="{ id: evento.id}">Inscribirse</a>
                                                        
                        </div>
                </div>
    
                <div class="row subheader">
                    <div class="col-lg-4 item">
                        <h4><i class="bx bxs-calendar"></i> {{evento.fecha_evento | date : 'dd-MM-yyyy' :
                            'UTC'}}
                            <span *ngIf="evento.fecha_fin_evento !== null">al {{evento.fecha_fin_evento | date : 'dd-MM-yyyy' :
                                'UTC'}}</span>
                        </h4>
                    </div>
                    <div class="col-lg-4 item">
                        <h4><i class="bx bxs-timer"></i> Citación: {{evento.hora_citacion}} <br>Largada: {{evento.hora_largada}}</h4>
                    </div>
                    <div class="col-lg-4 item">
                        <h4>
                            <i class="bx bxs-map"></i> {{evento.lugar}}
                        </h4>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!loading" class="page-title-area banner s">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                        <p><img *ngIf="evento.usuario.photoProfile !== null" style="margin-bottom: initial;
                            width: 45px;
                            height: 45px;
                            border: 2px solid #ffbe00;
                            margin-right: 10px;
                            border-radius: 21px;
                            object-fit: cover;" [src]="evento.usuario.photoProfile" />
                        <img *ngIf="evento.usuario.photoProfile === null" style="margin-bottom: initial;
                            width: 45px;
                            height: 45px;
                            border: 2px solid #ffbe00;
                            margin-right: 10px;
                            border-radius: 21px;
                            object-fit: cover;"
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/5778454.png?alt=media&token=3245a542-d833-4345-ab80-6835d629cbf9" />
                        {{evento.usuario.firstName}} {{evento.usuario.lastName}} presenta</p>
                        <h1 class="title">{{evento.nombre}}
                        </h1>
                        
                        <div class="button">
                            <a style="width: 50%;"
                            *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'ACTIVADO'"
                            class="cmn-btn" routerLink="/resultados" [queryParams]="{ id: evento.id}">Resultados</a>
                        <a style="width: 50%;"
                            *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'DESACTIVADO'"
                            routerLink="/calificar" class="cmn-btn"> Calificar</a>
    
                        <a style="width: 50%;"
                            *ngIf="evento.estado === 'POR_REALIZAR' && evento.cupos > evento.cantidad_inscritos"
                            class="cmn-btn" routerLink="/inscripcion"
                            [queryParams]="{ id: evento.id}">Inscribirse</a>

                            <a style="width: 50%;"
                            *ngIf="evento.estado === 'SOLICITUD_INSCRIPCIONES' && evento.cupos > evento.cantidad_inscritos"
                            class="cmn-btn" routerLink="/inscripcion"
                            [queryParams]="{ id: evento.id}">Inscribirse</a>
                                                        
                        </div>
                </div>
    
                <div class="subheader">
                    <div class="item">
                        <h4><i class="bx bxs-calendar"></i> {{evento.fecha_evento | date : 'dd-MM-yyyy' :
                            'UTC'}}
                            <span *ngIf="evento.fecha_fin_evento !== null">al {{evento.fecha_fin_evento | date : 'dd-MM-yyyy' :
                                'UTC'}}</span>
                        </h4>
                    </div>
                    <div class="item">
                        <h4><i class="bx bxs-timer"></i> Citación: {{evento.hora_citacion}} <br>Largada: {{evento.hora_largada}}</h4>
                    </div>
                    <div class="item">
                        <h4>
                            <i class="bx bxs-map"></i> {{evento.lugar}}
                        </h4>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!loading">
        <div class="estado red" *ngIf="evento.estado === 'SUSPENDIDO'">SUSPENDIDO</div>
    <div class="estado green" *ngIf="evento.estado === 'PROXIMAMENTE'">PRÓXIMAMENTE</div>
    <div class="estado red" *ngIf="evento.estado === 'INSCRIPCIONES_CERRADAS'">INSCRIPCIONES CERRADAS</div>
    <div class="estado red" *ngIf="evento.cupos === evento.cantidad_inscritos && evento.estado === 'POR_REALIZAR'">CUPOS AGOTADOS</div>
    <div class="estado green" *ngIf="evento.estado === 'REALIZADO'">FINALIZADO</div>
    <div class="estado yellow" *ngIf="evento.estado === 'SOLICITUD_INSCRIPCIONES'">SOLICITUD INSCRIPCIÓN</div>

    </ng-container>
    
    
<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3" *ngIf="!loading">
                <div class="services-details-item">
                    <div class="services-details-item">

                        <img [src]="evento.url_foto" alt="Service" style="margin-bottom: 16px;">

                        <br>
                    </div>

                    <div class="services-details-item" *ngIf="cantidadEventosAdicionalesValidos() > 0">
                        <div class="services-details-more">
                            <ul>
                                <li><a routerLink="/adicionales-evento" [queryParams]="{ id: evento.id}">Productos
                                        adicionales <i class='bx bx-plus'></i></a></li>

                            </ul>
                        </div>
                    </div>

                    <div class="services-details-item" *ngIf="evento.url_bases !== null">
                        <a [href]="evento.url_bases" style="color: white;" target="_blank">
                            <div class="services-details-order" style="background-color: #ffbe00">
                                <h3>Bases del evento</h3>
                                <img src="assets/img/pdf.png">
                                <br>
                            </div>
                        </a>
                    </div>

                    <div class="services-details-item" *ngIf="evento.url_strava !== null">
                        <a [href]="evento.url_strava" style="color: white;" target="_blank">
                            <div class="services-details-order" style="background-color: #fc4c01">
                                <h3>Ver ruta</h3>
                                <img src="assets/img/strava.jpeg">
                                <br>
                            </div>
                        </a>
                    </div>
                    <div class="services-details-item" style="margin-top: 10px;display: inline-block;">
                        <ng-adsense [adClient]="getEnvironmentAdSense()" [adSlot]="7460941019"></ng-adsense>
                    </div>

                </div>
            </div>

            <div class="col-lg-9">
                <div class="services-details-item">
                    <div class="services-details-fresh">
                        <br>
                        <h3 style="margin-bottom: 10px; text-align: center;" *ngIf="!loading">Acerca del evento: </h3>
                        <div class="arrow" style="margin-bottom: 50px" *ngIf="!loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <div class="descripcion-event" [innerHtml]="evento.descripcion" *ngIf="!loading"></div>

                        <h3 style="margin-bottom: 10px; text-align: center;" *ngIf="!loading">Categorías del evento:
                        </h3>

                        <div class="arrow" style="margin-bottom: 50px" *ngIf="!loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <table class="table" *ngIf="!loading">
                            <tr>
                                <th>Desafío</th>
                                <th>Categoría</th>
                                <th *ngIf="evento.mostrar_giros === 'ACTIVADO'" style="text-align: center;">Giros</th>

                                <ng-container *ngIf="evento.mostrar_precio === 'ACTIVADO'">
                                    <th *ngFor="let preventa of preventas" style="text-align: center;">
                                        {{preventa.titulo}} <br>
                                        <span *ngIf="preventa.mostrarCupos === 'ACTIVADO'" style="font-size: 12px;
                                    font-weight: bold;
                                    color: grey;
                                    word-wrap: break-word;"> Cupos: {{(preventa.cantidadMaxima -
                                            preventa.cantidadMinima) +1}}</span>
                                    </th>

                                </ng-container>

                            </tr>
                            <tr [attr.rowspan]="preventas.length + 2" style="height: 10px;"></tr>
                            <tr [attr.rowspan]="preventas.length + 2" style="border-bottom: solid 1px #dee2e6"></tr>
                            <tr [attr.rowspan]="preventas.length + 2" style="height: 10px;"></tr>

                            <ng-container *ngFor="let desafio of desafios; let d = index">
                                <tr *ngFor="let categoria of ordenarCategorias(desafio.nombre); let j = index">
                                    <td [attr.rowspan]="ordenarCategorias(desafio.nombre).length" *ngIf="j === 0">
                                        {{desafio.nombre}}</td>
                                    <td>{{categoria.nombre}} - {{categoria.sexo}}</td>
                                    <td style="text-align: center;" *ngIf="evento.mostrar_giros === 'ACTIVADO'">
                                        {{categoria.giros}}
                                    </td>
                                    <ng-container *ngIf="evento.mostrar_precio === 'ACTIVADO'">
                                        <td style="text-align: center;" *ngFor="let preventa of preventas;">
                                            ${{round(categoria.valor *
                                            obtenerMultiplicadorPreventa(preventa.porcentajeAumento))| number: '1.0-0'
                                            }}


                                        </td>
                                    </ng-container>


                                </tr>

                                <tr [attr.rowspan]="preventas.length + 2" style="height: 10px;"
                                    *ngIf="d<desafios.length-1"></tr>
                                <tr [attr.rowspan]="preventas.length + 2" style="border-bottom: solid 1px #dee2e6"
                                    *ngIf="d<desafios.length-1"></tr>
                                <tr [attr.rowspan]="preventas.length + 2" style="height: 10px;"
                                    *ngIf="d<desafios.length-1"></tr>
                            </ng-container>

                        </table>
                       
                        <br>
                        <h3 style="margin-bottom: 10px; text-align: center;" *ngIf="!loading">Lugar del evento: </h3>
                        <div class="arrow" style="margin-bottom: 50px" *ngIf="!loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                       
                        <div id="mapa-mapbox" style="width: 100%; height: 300px;"></div>
                        <br>
                            <h3 *ngIf="!loading && evento.mostrarRuta === 'ACTIVADO'" style="margin-bottom: 10px; text-align: center;">Ruta: </h3>
                            <div *ngIf="!loading && evento.mostrarRuta === 'ACTIVADO'" class="arrow" style="margin-bottom: 50px">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div #mapaRuta id="mapa-mapbox-ruta" style="width: 100%; height: 300px;"></div>
                            <br *ngIf="!loading && evento.mostrarRuta === 'ACTIVADO'">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading"></app-preloader>