import { Component } from '@angular/core';

@Component({
  selector: 'app-shimmer-medium',
  templateUrl: './shimmer-medium.component.html',
  styleUrls: ['./shimmer-medium.component.scss']
})
export class ShimmerMediumComponent {

}
