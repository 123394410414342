import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RutValidator } from 'ng9-rut';
import { firstValueFrom, forkJoin, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CorredorService } from '../../../services/corredor.service';
import { EventoService } from '../../../services/evento.service';
import { SolicitudCambioCategoriaService } from '../../../services/solicitud-cambio-categoria.service';

@Component({
    selector: 'app-cambio-categoria',
    templateUrl: './cambio-categoria.component.html',
    styleUrls: ['./cambio-categoria.component.scss']
})
export class CambioCategoriaComponent implements OnInit {

    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public formBusqueda: FormGroup;
    public formSolicitud: FormGroup;
    public eventos: any[] = [];
    public categorias: any[] = [];
    public solicitud;
    public corredor;
    public extranjero = false;


    constructor(private eventoService: EventoService,
        private corredorService: CorredorService,
        private solicitudCambioCategoriaService: SolicitudCambioCategoriaService,
        private fb: FormBuilder,
        private router: Router,
        private rutValidator: RutValidator,

    ) {

    }

    ngOnInit(): void {
        this.initFormBusqueda();
        this.initFormSolicitud();
    }


    initFormBusqueda() {
        this.formBusqueda = this.fb.group({
            rut: ['', [Validators.required, this.rutValidator, Validators.minLength(5)]],
        });
    }

    initFormSolicitud() {
        this.formSolicitud = this.fb.group({
            evento: [null, Validators.required],
            categoria: [null, Validators.required],
            descripcion: [null, Validators.required],
        });
    }

    buscarRut() {
        this.eventos = [];
        this.formSolicitud.reset();
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (!this.formBusqueda.valid) {
            swalWithBootstrapButtons.fire(
                'Campos incompletos',
                `El campo RUT debe ser válido.`,
                'error'
            );

            return;
        }

        this.loading = true;
        const petition: Observable<any> = this.eventoService.obtenerEventosPorCorredorRutYEstado(this.formBusqueda.get('rut').value, 'POR_REALIZAR,INSCRIPCIONES_CERRADAS');
        const petitionCorredor: Observable<any> = this.corredorService.obtenerCorredorPorRut(this.formBusqueda.get('rut').value);

        const petitions = forkJoin([petition, petitionCorredor]);

        petitions.pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventos = res[0];
                    this.corredor = res[1];
                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }

    changeEvento() {
        const evento = this.eventos.find((eventoFind) => eventoFind.id === Number(this.formSolicitud.get('evento').value));

        const fechaFinEvento = new Date(evento.fecha_evento)
        const fechaInicio = new Date(this.corredor.fecha_nacimiento);
        let diferenciaAnoPersona1 = 0;

        switch (evento.tipo_calculo_edad) {
            case 'RESTA_ANOS':
                diferenciaAnoPersona1 = Math.floor(fechaFinEvento.getFullYear() - fechaInicio.getFullYear())
                break;
            case 'RESTA_ANOS_DIAS':
                const edadEnMilisegundos = fechaFinEvento.getTime() - fechaInicio.getTime();
                const edadEnAnios = edadEnMilisegundos / (1000 * 60 * 60 * 24 * 365);
                diferenciaAnoPersona1 = Math.floor(edadEnAnios);
                break;

        }

        this.categorias = evento.categorias.filter((categoria) =>
            (categoria.sexo === (this.corredor.sexo === 'femenino' ? 'Damas' : 'Varones') ||
                categoria.sexo === 'Mixto') &&
            categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1
        );


    }

    enviarSolicitud() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (!this.formSolicitud.valid) {
            swalWithBootstrapButtons.fire(
                'Campos incompletos',
                `Todos los campos del formulario deben ser completados`,
                'error'
            );

            return;
        }

        const petitionData = {
            eventoId: Number(this.formSolicitud.get('evento').value),
            categoriaId: Number(this.formSolicitud.get('categoria').value),
            rut: this.formBusqueda.get('rut').value,
            descripcion: this.formSolicitud.get('descripcion').value,
        };


        this.loading = true;
        const petition: Observable<any> = this.solicitudCambioCategoriaService.crearSolicitudCodigo(petitionData);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.solicitud = res;
                    this.loading = false;
                    Swal.fire({
                        title: 'Código de confirmación',
                        text: 'Te hemos enviado un código dinámico de confirmación a tu correo electrónico, ingresalo acá para confirmar la solicitud.',
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        cancelButtonText: 'Cancelar',
                        showLoaderOnConfirm: true,
                        preConfirm: (codigo) => {

                            return firstValueFrom(this.solicitudCambioCategoriaService.validacionSolicitudCodigo(this.solicitud.id, { 'codigo': codigo })).then((data) => {
                                console.log(data);
                            }).catch((error) => {
                                console.log(error);
                                Swal.showValidationMessage(
                                    JSON.parse(error.error).message
                                );
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                        if (result.isConfirmed) {
                            swalWithBootstrapButtons.fire(
                                'Solicitud enviada!',
                                'Has enviado una solicitud al equipo Bikerace, pronto recibirás la respuesta de la solicitud en tu correo electrónico.',
                                'success'
                            );

                            this.router.navigate(['/']);


                        }
                    });
                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }

    changeExtranjero(esExtranjero: boolean) {
        this.extranjero = esExtranjero;
        this.changeTypeDocument();
    }

    changeTypeDocument() {

        switch (this.extranjero) {
            case false:
                const validator: any[] = [Validators.required, this.rutValidator, Validators.minLength(5)];
                this.formBusqueda.get('rut').setValidators(validator);
                break;
            case true:
                this.formBusqueda.get('rut').setValidators([Validators.required]);

                break;
        }
    }
}
