import { Component } from '@angular/core';

@Component({
  selector: 'app-shimmer-tiny',
  templateUrl: './shimmer-tiny.component.html',
  styleUrls: ['./shimmer-tiny.component.scss']
})
export class ShimmerTinyComponent {

}
