import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShimmerSmallComponent } from './shimmer-small/shimmer-small.component';
import { ShimmerMediumComponent } from './shimmer-medium/shimmer-medium.component';
import { ShimmerLargeComponent } from './shimmer-large/shimmer-large.component';
import { ShimmerTinyComponent } from './shimmer-tiny/shimmer-tiny.component';

@NgModule({
  declarations: [
    ShimmerSmallComponent,
    ShimmerMediumComponent,
    ShimmerLargeComponent,
    ShimmerTinyComponent
  ],
  imports: [
    CommonModule,
  ],
  exports:[
    ShimmerSmallComponent,
    ShimmerMediumComponent,
    ShimmerLargeComponent,
    ShimmerTinyComponent
  ]
})
export class ShimmersModule { }
