import { Component } from '@angular/core';

@Component({
  selector: 'app-shimmer-large',
  templateUrl: './shimmer-large.component.html',
  styleUrls: ['./shimmer-large.component.scss']
})
export class ShimmerLargeComponent {

}
