import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MathHelper } from 'src/app/shared/math_helper';

@Component({
    selector: 'app-evento-item',
    templateUrl: './evento-item.component.html',
    styleUrls: ['./evento-item.component.scss'],
})
export class EventoItemComponent implements OnInit {

    @Input() public eventosFiltro = [];

    constructor(
        private mathHelper: MathHelper,
        private router: Router,

    ) {

    }

    obtenerDias(evento) {
        const fechaDada: Date = new Date(evento.fecha_evento);
        const fechaDada2: Date = new Date(fechaDada.getTime() + fechaDada.getTimezoneOffset() * 60000); const fechaActual = new Date();
        const diferencia = fechaDada2.getTime() - fechaActual.getTime(); // Diferencia en milisegundos
        return Math.floor(diferencia / (1000 * 60 * 60 * 24))

    }



    obtenerHoras(evento) {
        const fechaDada: Date = new Date(evento.fecha_evento);
        const fechaDada2: Date = new Date(fechaDada.getTime() + fechaDada.getTimezoneOffset() * 60000);


        const fechaActual = new Date();
        const diferencia = fechaDada2.getTime() - fechaActual.getTime(); // Diferencia en milisegundos
        return Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    }

    ngOnInit(): void {
    }

    obtenerMenorPrecio(evento: any) {
        return this.mathHelper.obtenerMenor(evento.categorias, 'valor');
    }

    irACalificar(evento) {
        if (evento.estado === 'REALIZADO') {
            this.router.navigate(['/calificar'], { queryParams: { id: `${evento.id}` } });

        }

    }

}
