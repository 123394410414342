import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RutValidator } from 'ng9-rut';
import { Observable, Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DesafioService } from 'src/app/services/desafio.service';
import { RegionService } from 'src/app/services/region.service';
import Swal from 'sweetalert2';
import { CategoriaService } from '../../../services/categoria.service';
import { CorredorService } from '../../../services/corredor.service';
import { CuponService } from '../../../services/cupon.service';
import { EventoService } from '../../../services/evento.service';
import { EncryptService } from '../../../shared/encrypt';
import { LocalStorageService } from '../../../shared/storage';

@Component({
    selector: 'app-inscription',
    templateUrl: './inscription.component.html',
    styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {

    public id = 0;
    public loading = false;
    public loadingInfoInicial = false;
    public categoria: any = null;
    public evento: any;
    public cupon: any = null;
    private compDestroy: Subject<boolean> = new Subject();
    public formInscripcion: FormGroup;
    public formCupon: FormGroup;
    public formInscripcionPersona2: FormGroup;
    public dataUser = null;
    public mostrarRestriccionEdad = false;
    public errorRestriccionEdad = false;
    public extranjero1 = false;
    public extranjero2 = false;
    public adicionales = [];

    public adicionalesSeleccionados = [];

    // V2
    public categorias: any[] = [];
    public desafios = [];
    public tallasTricotas = [];
    public regiones = [];
    public ciudades = [];
    public ciudadesPersona2 = [];
    public desafioEvento = [];
    public camposAdicionales = [];
    public camposAdicionalesForm = [];


    constructor(private activatedroute: ActivatedRoute,
        private categoriaService: CategoriaService,
        private eventoService: EventoService,
        private corredorService: CorredorService,
        private fb: FormBuilder,
        private router: Router,
        private cuponService: CuponService,
        private localStorageAs: LocalStorageService,
        private encryptService: EncryptService,
        private rutValidator: RutValidator,
        private regionService: RegionService,
        private desafioService: DesafioService,

    ) {
        this.activatedroute.queryParams.subscribe(params => {
            this.id = Number(params.id);
        });
    }

    ngOnInit(): void {
        this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));
        this.categoriaService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getEventoYCategorias();
                    }
                });


        this.getEventoYCategorias();


    }

    getEventoYCategorias() {
        this.loadingInfoInicial = true;
        const obtenerCategoria = this.categoriaService.obtenerCategoriaByEventoId(this.id);
        const obtenerEvento = this.eventoService.obtenerEventoById(this.id);
        const obtenerAdicionalesByEventoId = this.eventoService.obtenerAdicionalesByEventoId(this.id);
        const obtenerDesafiosPorEventoId = this.eventoService.obtenerDesafiosPorEventoId(this.id);
        const obtenerDesafioEventoPorEventoId = this.desafioService.obtenerTodosDesafiosEvento(this.id);
        const obtenerCamposAdicionalesPorEventoId = this.eventoService.obtenerCamposAdicionalesPorEventoId(this.id);

        const petitions = forkJoin([obtenerCategoria, obtenerEvento, obtenerAdicionalesByEventoId, obtenerDesafiosPorEventoId, obtenerDesafioEventoPorEventoId, obtenerCamposAdicionalesPorEventoId]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.categorias = res[0];

                    this.evento = res[1];
                    if (this.evento.estado !== 'POR_REALIZAR' && this.evento.estado !== 'SOLICITUD_INSCRIPCIONES') {
                        Swal.fire({
                            icon: 'warning',
                            title: "Inscripciones no habilitadas",
                            text: "Las inscripciones para el evento no se encuentran habilitadas, contacta al organizador del evento",
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: "Ver descripción del evento",
                            allowOutsideClick: false
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                this.router.navigate(['/evento'], { queryParams: { id: this.evento.id } });

                            }
                        });

                    }

                    this.adicionales = res[2].filter((adicional) => adicional.cupos > adicional.vendidos);
                    this.desafios = res[3];
                    this.desafioEvento = res[4];
                    this.camposAdicionales = res[5];
                    this.initForm();
                    this.initFormPersona2();
                    this.initFormCupon();

                    this.changeExtranjero1(false);
                    this.changeExtranjero2(false);

                    if (this.dataUser) {
                        this.formInscripcion.get('nombre').setValue(this.dataUser.firstName);
                        this.formInscripcion.get('apellido').setValue(this.dataUser.lastName);
                        this.formInscripcion.get('fecha_nacimiento').setValue(this.dataUser.birthDate);
                        this.formInscripcion.get('email').setValue(this.dataUser.email);
                        this.formInscripcion.get('team').setValue(this.dataUser.team);
                        this.formInscripcion.get('phoneNumber').setValue(this.dataUser.phoneNumber);
                        this.formInscripcion.get('rut').setValue(this.dataUser.rut.replaceAll('-', '').replaceAll('.', '').toUpperCase());
                        this.formInscripcion.get('typeOfDocument').setValue(this.dataUser.typeOfDocument === null ? 'RUT' : this.dataUser.typeOfDocument);
                        if (this.dataUser.typeOfDocument === 'RUT') {
                            this.changeExtranjero1(false);
                        }

                        if (this.dataUser.typeOfDocument === 'passport') {
                            this.changeExtranjero1(true);
                        }
                    }

                    this.tallasTricotas = this.evento.tallasTricotas;

                    if (this.evento.solicitar_ciudad === 'ACTIVADO') {
                        this.obtenerRegiones();
                    } else {
                        this.loadingInfoInicial = false;
                    }


                },
                err => {
                    console.log(err);
                    this.loadingInfoInicial = false;
                });
    }

    obtenerRegiones() {
        this.loadingInfoInicial = true;
        const obtenerRegion = this.regionService.obtenerRegion();


        const petitions = forkJoin([obtenerRegion]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.regiones = res[0];
                    this.loadingInfoInicial = false;
                },
                err => {
                    console.log(err);
                    this.loadingInfoInicial = false;
                });
    }

    obtenerCiudades(form1: boolean) {
        // this.loadingInfoInicial = true;
        this.ciudades = [];
        const obtenerRegion = this.regionService.obtenerCiudadesPorRegion(Number(form1 ? this.formInscripcion.get('region').value : this.formInscripcionPersona2.get('region').value));


        const petitions = forkJoin([obtenerRegion]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    if (form1) {
                        this.ciudades = res[0];
                    } else {
                        this.ciudadesPersona2 = res[0];
                    }

                    // this.loadingInfoInicial = false;
                },
                err => {
                    console.log(err);
                    // this.loadingInfoInicial = false;
                });
    }


    initForm() {
        this.formInscripcion = this.fb.group({
            nombre: ['', Validators.required],
            apellido: ['', Validators.required],
            evento: [this.evento, Validators.required],
            fecha_nacimiento: [null, Validators.required],
            sexo: ['masculino', Validators.required],
            categoria: [null, Validators.required],
            federado: [{ value: this.evento.opcion_federado === 'NO_FEDERADO' ? 'No federado' : this.evento.opcion_federado === 'FEDERADO' ? 'Federado' : 'No federado', disabled: this.evento.opcion_federado === 'NO_FEDERADO' || this.evento.opcion_federado === 'FEDERADO' }, Validators.required],
            id_uci: [null],
            talla_tricota: [null, this.evento.solicitar_talla_tricota === 'ACTIVADO' ? [Validators.required] : []],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            typeOfDocument: ['RUT', Validators.required],
            phoneNumber: ['', [Validators.required]],
            rut: ['', [Validators.required, this.rutValidator, Validators.minLength(5)]],
            team: [null],
            desafio: ['0'],
            ciudad: [null, this.evento.solicitar_ciudad === 'ACTIVADO' ? [Validators.required] : []],
            region: [],
            ip: (this.localStorageAs.get('ip_connection') === null || this.localStorageAs.get('ip_connection') === undefined) ? null : this.localStorageAs.get('ip_connection'),
            campos_adicionales: this.fb.group({})

        });

        this.formInscripcion.get('categoria').disable();

        this.agregarCamposAdicionales(this.formInscripcion);


    }

    agregarCamposAdicionales(form: FormGroup) {
        const camposFormGroup = this.fb.group({});
        this.camposAdicionales.forEach(campo => {
            const control = this.fb.control(
                '',
                campo.campoAdicional.obligatorio ? Validators.required : null
            );
            camposFormGroup.addControl(campo.campoAdicional.label.replace(/ /g, '_'), control);
        });


        form.setControl('campos_adicionales', camposFormGroup);
        this.inicializarCamposAdicionales(form);
    }

    inicializarCamposAdicionales(form: FormGroup) {
        const group = form.get('campos_adicionales') as FormGroup;
        this.camposAdicionalesForm = Object.keys(group.controls).map(key => ({ key }));
    }

    limpiarLabel(label: string) {
        return label.replace(/_/g, ' ');

    }

    obtenerCampoAdicional(key: string) {
        return this.camposAdicionales.find((campoAdicional) => campoAdicional.campoAdicional.label.replace(/ /g, '_') === key);
    }

    obtenerOpcionesLista(key: string) {
        const opciones = this.obtenerCampoAdicional(key).campoAdicional.opciones;
        return opciones.split(',');
    }

    isFieldInvalid(fieldKey: string, form: FormGroup): boolean {
        const control = (form.get('campos_adicionales') as FormGroup).get(fieldKey);
        return control?.invalid && (control.dirty || control.touched);
    }


    initFormCupon() {
        this.formCupon = this.fb.group({
            codigo: [null],
            eventoId: [this.id, Validators.required],
        });

    }

    initFormPersona2() {
        this.formInscripcionPersona2 = this.fb.group({
            nombre: ['', Validators.required],
            apellido: ['', Validators.required],
            evento: [this.evento, Validators.required],
            fecha_nacimiento: [null, Validators.required],
            sexo: [null, Validators.required],
            categoria: [null, Validators.required],
            email: ['', Validators.required],
            phoneNumber: ['', [Validators.required]],
            federado: [{ value: this.evento.opcion_federado === 'NO_FEDERADO' ? 'No federado' : this.evento.opcion_federado === 'FEDERADO' ? 'Federado' : 'No federado', disabled: this.evento.opcion_federado === 'NO_FEDERADO' || this.evento.opcion_federado === 'FEDERADO' }, Validators.required],
            id_uci: [null],
            talla_tricota: [null, this.evento.solicitar_talla_tricota === 'ACTIVADO' ? [Validators.required] : []],
            rut: ['', [Validators.required, this.rutValidator, Validators.minLength(5)]],
            typeOfDocument: ['RUT', Validators.required],
            team: [null],
            desafio: ['0'],
            ciudad: [null, this.evento.solicitar_ciudad === 'ACTIVADO' ? [Validators.required] : []],
            region: [],
            ip: (this.localStorageAs.get('ip_connection') === null || this.localStorageAs.get('ip_connection') === undefined) ? null : this.localStorageAs.get('ip_connection'),
            campos_adicionales: this.fb.group({})

        });

        this.agregarCamposAdicionales(this.formInscripcionPersona2);

    }

    mostrarIdUCIPersona1() {
        return this.formInscripcion.get('federado').value === 'Federado';
    }

    mostrarIdUCIPersona2() {
        return this.formInscripcion.get('federado').value === 'Federado';
    }

    porcentajePreventa() {
        const preventa = this.evento.preventas.find(
            (preventaFind) =>
                preventaFind.cantidadMinima <= this.evento.cantidad_inscritos &&
                preventaFind.cantidadMaxima >= this.evento.cantidad_inscritos,
        );


        let porcentajeAumento = 1;
        if (preventa) {
            porcentajeAumento = (1 + (preventa.porcentajeAumento / 100));
        }

        return porcentajeAumento;

    }

    initTransaccion() {
        this.loading = true;
        const compra = {
            fecha: Date.now(),
            transacciones: [
                {
                    corredor: this.categoria.modo === 'duplas' ?
                        [this.formInscripcion.getRawValue(), this.formInscripcionPersona2.getRawValue()] :
                        [this.formInscripcion.getRawValue()],
                    monto: this.categoria.modo === 'individual' ?
                        Math.ceil(this.categoria.valor * this.porcentajePreventa()) :
                        Math.ceil(this.categoria.valor * this.porcentajePreventa()) * 2,
                    evento: this.formInscripcion.get('evento').value,
                    categoria: this.formInscripcion.get('categoria').value,
                    cupon: this.cupon,
                    transaccionId : 0,
                }
            ],
            adicionales: this.generarAdicionales()
        };

        let transaccion: any[] = [];
        let adicional: any[] = [];
        if (JSON.parse(this.localStorageAs.get('carro_compra')) !== null) {
            adicional = JSON.parse(this.localStorageAs.get('carro_compra')).adicionales === undefined ? [] : JSON.parse(this.localStorageAs.get('carro_compra')).adicionales;
            transaccion = JSON.parse(this.localStorageAs.get('carro_compra')).transacciones === undefined ? [] : JSON.parse(this.localStorageAs.get('carro_compra')).transacciones;
        }
        compra.transacciones = compra.transacciones.concat(transaccion);
        compra.adicionales = compra.adicionales.concat(adicional);
        this.localStorageAs.set('carro_compra', JSON.stringify(compra));


        this.loading = false;

        this.router.navigate(['/carro']);
    }

    generarAdicionales() {
        const adicionalesReturn = [];
        this.adicionalesSeleccionados.forEach((adicional) => {
            adicionalesReturn.push(adicional.formAdicional);
        });

        return adicionalesReturn;
    }


    getMontoFinal() {
        return this.categoria.modo === 'individual' ?
            Math.ceil(this.categoria.valor * this.porcentajePreventa()) :
            Math.ceil(this.categoria.valor * this.porcentajePreventa()) * 2;
    }

    getMontoFinalAdicionales() {
        let total = 0;
        this.obtenerAdicionalValidado().forEach((adicional) => {
            total = total + adicional.formAdicional.eventoAdicional.precio;
        });
        return Math.ceil(total);
    }

    getMontoCargosAdicionales() {
        let total = 0;
        this.obtenerAdicionalValidado().forEach((adicional) => {
            total = total + (adicional.formAdicional.eventoAdicional.precio * adicional.formAdicional.porcentaje_cobro)
        });
        return Math.ceil(total);
    }

    getMontoCargos() {
        return Math.ceil(this.getMontoFinal() * this.evento.configuracionPrecio.porcentaje_cobro);
    }

    changeCategoria() {
        const categoriaValidacion = this.obtenerCategoriasFiltradas().find((categoria) => categoria.id === Number(this.formInscripcion.get('categoria').value));
        if (categoriaValidacion === undefined) {
            return;
        }

        this.categoria = categoriaValidacion;

        if (this.categoria.modo === 'duplas') {
            if (this.categoria.sumatoriaMinima !== null && this.categoria.sumatoriaMaxima !== null) {
                this.mostrarRestriccionEdad = true;
            } else {
                this.mostrarRestriccionEdad = false;
            }

            this.formInscripcionPersona2.get('categoria').setValue(this.categoria.id);
            this.formInscripcionPersona2.get('federado').setValue(this.formInscripcion.get('federado').value);
            switch (this.categoria.sexo) {
                case 'Varones':
                    this.formInscripcionPersona2.get('sexo').setValue('masculino');
                    this.formInscripcionPersona2.get('sexo').disable();
                    break;
                case 'Damas':
                    this.formInscripcionPersona2.get('sexo').setValue('femenino');
                    this.formInscripcionPersona2.get('sexo').disable();
                    break;
                case 'Mixto':
                    if (this.formInscripcion.get('sexo').value === 'masculino') {
                        this.formInscripcionPersona2.get('sexo').setValue('femenino');
                    }

                    if (this.formInscripcion.get('sexo').value === 'femenino') {
                        this.formInscripcionPersona2.get('sexo').setValue('masculino');

                    }
                    break;
            }

            this.formInscripcionPersona2.get('categoria').disable();
        } else {
            this.formInscripcion.get('categoria').setValue(this.categoria.id);
            this.mostrarRestriccionEdad = false;
            this.errorRestriccionEdad = false;
        }
    }

    filtrarCategorias() {
        if (this.formInscripcion.get('fecha_nacimiento').value !== null && this.formInscripcion.get('sexo').value !== null) {

            if (this.obtenerCategoriasFiltradas().length > 0) {


                const obtenerCategoriaDisponible = this.obtenerCategoriasFiltradas().find((categoria) => categoria.configuracionCategorias[0].estado === 'DISPONIBLE' && !this.obtenerDesafioSinCupo(categoria));

                if (obtenerCategoriaDisponible) {
                    this.formInscripcion.get('categoria').setValue(obtenerCategoriaDisponible.id);
                    this.changeCategoria();

                    this.formInscripcion.get('categoria').enable();

                    if (this.categoria.modo === 'duplas') {
                        this.formInscripcionPersona2.get('desafio').setValue(this.formInscripcion.get('desafio').value);
                        this.formInscripcionPersona2.get('desafio').disable();

                    }
                }


            } else {
                this.formInscripcion.get('categoria').setValue(null);
                this.categoria = null;
            }

        }

    }

    changeFechaInscripcion2() {

        const fechaFin = new Date(this.evento.fecha_evento);

        if (this.categoria.modo === 'duplas' && this.categoria.sumatoriaMinima !== null && this.categoria.sumatoriaMaxima !== null) {
            const fechaInicio = new Date(this.formInscripcion.get('fecha_nacimiento').value);
            const fechaInicioPersona2 = new Date(this.formInscripcionPersona2.get('fecha_nacimiento').value);

            let diferenciaAnoPersona1 = 0;
            let diferenciaAnoPersona2 = 0;

            switch (this.evento.tipo_calculo_edad) {
                case 'RESTA_ANOS':
                    diferenciaAnoPersona1 = Math.floor(fechaFin.getFullYear() - fechaInicio.getFullYear());
                    diferenciaAnoPersona2 = Math.floor(fechaFin.getFullYear() - fechaInicioPersona2.getFullYear());

                    break;
                case 'RESTA_ANOS_DIAS':
                    const edadEnMilisegundos = fechaFin.getTime() - fechaInicio.getTime();
                    const edadEnAnios = edadEnMilisegundos / (1000 * 60 * 60 * 24 * 365);
                    diferenciaAnoPersona1 = Math.floor(edadEnAnios);

                    const edadEnMilisegundos2 = fechaFin.getTime() - fechaInicioPersona2.getTime();
                    const edadEnAnios2 = edadEnMilisegundos2 / (1000 * 60 * 60 * 24 * 365);
                    diferenciaAnoPersona2 = Math.floor(edadEnAnios2);
                    break;

            }



            this.errorRestriccionEdad = diferenciaAnoPersona1 + diferenciaAnoPersona2 > this.categoria.sumatoriaMaxima ||
                diferenciaAnoPersona1 + diferenciaAnoPersona2 < this.categoria.sumatoriaMinima;

        } else {

            this.errorRestriccionEdad = false;

        }
    }

    obtenerCategoriasFiltradas() {
        let resultado;

        const fechaFinEvento = new Date(this.evento.fecha_evento)
        const fechaInicio = new Date(this.formInscripcion.get('fecha_nacimiento').value);
        let diferenciaAnoPersona1 = 0;

        switch (this.evento.tipo_calculo_edad) {
            case 'RESTA_ANOS':
                diferenciaAnoPersona1 = Math.floor(fechaFinEvento.getFullYear() - fechaInicio.getFullYear())
                break;
            case 'RESTA_ANOS_DIAS':
                const edadEnMilisegundos = fechaFinEvento.getTime() - fechaInicio.getTime();
                const edadEnAnios = edadEnMilisegundos / (1000 * 60 * 60 * 24 * 365);
                diferenciaAnoPersona1 = Math.floor(edadEnAnios);
                break;

        }

        if (Number(this.formInscripcion.get('desafio').value) === 0) {

            resultado = this.categorias.filter((categoria) =>
                (this.formInscripcion.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'ACTIVADA')) &&
                (categoria.sexo === (this.formInscripcion.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                    categoria.sexo === 'Mixto') &&
                categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1
            );
        } else {
            resultado = this.categorias.filter((categoria) =>
                (this.formInscripcion.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'ACTIVADA')) &&
                (categoria.sexo === (this.formInscripcion.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                    categoria.sexo === 'Mixto') &&
                categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1 &&
                categoria.desafioEntity.id === Number(this.formInscripcion.get('desafio').value)
            );
        }
        return resultado;

    }

    obtenerDesafioSinCupo(categoria) {
        if (this.desafioEvento.length === 0) {
            return false;
        }

        const desafioEventoFind = this.desafioEvento.find((desafioEvento) => desafioEvento.desafio.id === categoria.desafioEntity.id);
        if (desafioEventoFind === undefined) {
            return false;
        }

        if (desafioEventoFind.cupos <= desafioEventoFind.cantidad_inscritos) {
            return true;
        }

        return false;
    }

    obtenerDesafioPorDesafioSinCupo(desafio) {
        if (this.desafioEvento.length === 0) {
            return false;
        }

        const desafioEventoFind = this.desafioEvento.find((desafioEvento) => desafioEvento.desafio.id === desafio.id);
        if (desafioEventoFind === undefined) {
            return false;
        }

        if (desafioEventoFind.cupos <= desafioEventoFind.cantidad_inscritos) {
            return true;
        }

        return false;
    }

    obtenerCategoriasFiltradasDupla() {
        let resultado;
        // const fechaFin = new Date();


        const fechaFinEvento = new Date(this.evento.fecha_evento)
        const fechaInicio = new Date(this.formInscripcionPersona2.get('fecha_nacimiento').value);
        let diferenciaAnoPersona1 = 0;

        switch (this.evento.tipo_calculo_edad) {
            case 'RESTA_ANOS':
                diferenciaAnoPersona1 = Math.floor(fechaFinEvento.getFullYear() - fechaInicio.getFullYear())
                break;
            case 'RESTA_ANOS_DIAS':
                const edadEnMilisegundos = fechaFinEvento.getTime() - fechaInicio.getTime();
                const edadEnAnios = edadEnMilisegundos / (1000 * 60 * 60 * 24 * 365);
                diferenciaAnoPersona1 = Math.floor(edadEnAnios);
                break;

        }

        if (Number(this.formInscripcionPersona2.get('desafio').value) === 0) {
            resultado = this.categorias.filter((categoria) =>
                (this.formInscripcionPersona2.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                (categoria.sexo === (this.formInscripcionPersona2.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                    categoria.sexo === 'Mixto') &&
                categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1
            );
        } else {
            resultado = this.categorias.filter((categoria) =>
                (this.formInscripcionPersona2.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                (categoria.sexo === (this.formInscripcionPersona2.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                    categoria.sexo === 'Mixto') &&
                categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1 &&
                categoria.desafioEntity.id === Number(this.formInscripcionPersona2.get('desafio').value)
            );
        }
        return resultado;

    }


    incripcionClick() {
        if (this.evento.estado === 'SOLICITUD_INSCRIPCIONES' && this.categoria.modo === 'duplas') {
            Swal.fire(
                'Lo sentimos...',
                'No puedes enviar una solicitud de inscripción seleccionando una categoría duplas.',
                'error'
            );
            return;
        }

        Swal.fire({
            icon: 'question',
            title: 'Bases del evento',
            text: 'He leído atentamente, estoy de acuerdo y acepto las bases del evento',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Si, acepto!",
            cancelButtonText: `No, cancelar!`
        }).then((resultTerminosYCondiciones) => {
            if (resultTerminosYCondiciones.isConfirmed) {
                this.categoria = this.categorias.find((categoria) => categoria.id === Number(this.formInscripcion.get('categoria').value));

                if (this.formInscripcion.get('federado').value !== 'Federado') {
                    this.formInscripcion.get('id_uci').setValue(null);
                    this.formInscripcionPersona2.get('id_uci').setValue(null);
                }

                if (this.categoria.modo === 'duplas') {
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcion.get('evento')!.setValue(this.evento);
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcion.get('categoria')!.setValue(this.categoria);

                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcionPersona2.get('evento')!.setValue(this.evento);
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcionPersona2.get('categoria')!.setValue(this.categoria);

                } else {
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcion.get('evento')!.setValue(this.evento);
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcion.get('categoria')!.setValue(this.categoria);
                }

                if (this.categoria.modo === 'duplas') {

                    if (!this.formInscripcion.valid || !this.formInscripcionPersona2.valid) {
                        // tslint:disable-next-line:no-shadowed-variable

                        Swal.fire(
                            'Falta información!',
                            'Existen campos inválidos y/o incompletos.',
                            'error'
                        );
                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcion.get('categoria')!.setValue(this.categoria.id);
                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcionPersona2.get('categoria')!.setValue(this.categoria.id);

                        return;
                    }

                    if (((this.formInscripcion.get('federado').value === 'Federado' && (this.formInscripcion.get('id_uci').value === null || this.formInscripcion.get('id_uci').value.length < 5)) || (this.formInscripcionPersona2.get('federado').value === 'Federado' && (this.formInscripcionPersona2.get('id_uci').value === null || this.formInscripcionPersona2.get('id_uci').value.length < 5)))) {


                        Swal.fire(
                            'Falta completar el ID UCI',
                            'Si eres deportista federado, debes completar el ID UCI',
                            'error'
                        );

                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcion.get('categoria')!.setValue(this.categoria.id);
                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcionPersona2.get('categoria')!.setValue(this.categoria.id);

                        return;
                    }

                } else {
                    if (!this.formInscripcion.valid) {


                        Swal.fire(
                            'Falta información!',
                            'Existen campos inválidos y/o incompletos.',
                            'error'
                        );

                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcion.get('categoria')!.setValue(this.categoria.id);
                        return;
                    }

                    if (((this.formInscripcion.get('federado').value === 'Federado' && this.formInscripcion.get('id_uci').value === null))) {


                        Swal.fire(
                            'Falta completar el ID UCI',
                            'Si eres deportista federado, debes completar el ID UCI',
                            'error'
                        );
                        return;
                    }
                }

                switch (this.evento.estado) {
                    case 'POR_REALIZAR':
                        if (this.categoria.valor > 0) {
                            this.initTransaccion();
                        } else {
                            Swal.fire({
                                icon: 'question',
                                title: "Confirmar inscripción",
                                html: this.mensajeValor(),
                                showDenyButton: false,
                                showCancelButton: true,
                                confirmButtonText: "Confirmar inscripción",
                                cancelButtonText: `No, cancelar!`
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.saveCorredorSinPago();

                                } else if (
                                    /* Read more about handling dismissals below */
                                    result.dismiss === Swal.DismissReason.cancel
                                ) {
                                    Swal.fire({
                                        title: "No te preocupes!",
                                        text: "Podrás inscribirte en el evento que quieras :)",
                                        icon: "success"
                                    });
                                }
                            });
                        }
                        break;
                    case 'SOLICITUD_INSCRIPCIONES':
                        this.saveSolicitudInscripcion();

                        break;
                }
            }
        });
    }

    validarCupon() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (this.categoria === null) {
            swalWithBootstrapButtons.fire(
                'Seleccion de categoría',
                'Para validar un cupón primero debes rellenar los  datos de tu inscripción',
                'error'
            );
            return;
        }

        this.loading = true;
        const petition: Observable<any> = this.cuponService.validarCupon(
            this.formCupon.get('codigo').value, this.formCupon.get('eventoId').value === null ? 0
            : this.formCupon.get('eventoId').value);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    if (res !== null && res.disponible === true) {
                        swalWithBootstrapButtons.fire({
                            title: 'El cupón es válido!',
                            text: `Quieres incluir el cupón en esta inscripción? `,
                            showCancelButton: true,
                            confirmButtonText: 'Si, ocupar cupón',
                            cancelButtonText: 'No, cancelar!',
                            reverseButtons: true,
                            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/Grupo%20856.png?alt=media&token=9ca3e1e8-470a-4b4a-9901-3a798b18de2c',
                            imageHeight: 100,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.cupon = res;
                                swalWithBootstrapButtons.fire(
                                    'Cupón agregado',
                                    'Se ha agregado el cupón a la inscripción.',
                                    'success'
                                );
                            } else if (
                                /* Read more about handling dismissals below */
                                result.dismiss === Swal.DismissReason.cancel
                            ) {
                                swalWithBootstrapButtons.fire(
                                    'No te preocupes!',
                                    'Podrás utilizar el cupón cuando quieras :)',
                                    'error'
                                );
                            }
                        });
                    } else {

                        swalWithBootstrapButtons.fire(
                            'Cupón no válido',
                            `El cupón ${this.formCupon.get('codigo').value === null ? '-' : this.formCupon.get('codigo').value} no es válido.`,
                            'error'
                        );
                    }

                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });



    }

    saveSolicitudInscripcion() {
        this.loading = true;

        let petition: Observable<any> = this.corredorService.crearSolicitud(this.formInscripcion.getRawValue(), '0');

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {

                    Swal.fire(
                        'Felicitaciones!',
                        'Has registrado la solicitud de inscripción al evento. Pronto recibirás noticias en tu correo electrónico.',
                        'success'
                    );
                    this.loading = false;

                    this.router.navigate(['/evento'], { queryParams: { id: this.evento.id } });


                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }

    saveCorredorSinPago() {
        this.loading = true;

        let petition: Observable<any>;
        if (this.categoria.modo === 'duplas') {
            petition = this.corredorService.crearCorredores(this.formInscripcion.getRawValue(), this.formInscripcionPersona2.getRawValue(), '0');
        } else {
            petition = this.corredorService.crearCorredor(this.formInscripcion.getRawValue(), '0');
        }
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {

                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    });

                    swalWithBootstrapButtons.fire(
                        'Felicitaciones!',
                        'Te has inscrito al evento.',
                        'success'
                    );
                    this.loading = false;

                    this.router.navigate(['/evento'], { queryParams: { id: this.evento.id } });


                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }

    mensajeValor(): string {
        return 'El precio a pagar es: ' +
            '<br>Valor inscripción: $0' +
            '<br>Costos por servicios y transacción: $0' +
            '<br><strong>Total a pagar: $0 </strong>';

    }



    showRestriccion() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire(
            'Restricción!',
            `Esta categoria posee una restricción de edad creada por el organizador del evento, la sumatoria de las edades en la categoria ${this.categoria.nombre} - ` +
            `${this.categoria.modo} - ${this.categoria.sexo} debe ser mayor o igual a ${this.categoria.sumatoriaMinima} años y menor o igual` +
            ` a ${this.categoria.sumatoriaMaxima} años.`,
            'error'
        );
    }

    getTipoDocumento1() {
        return this.formInscripcion.get('typeOfDocument').value;
    }

    changeTypeDocument1() {
        switch (this.getTipoDocumento1()) {

            case 'RUT':
                const validator: any[] = [Validators.required, this.rutValidator, Validators.minLength(5)];
                this.formInscripcion.get('rut').setValidators(validator);
                this.formInscripcion.get('rut').updateValueAndValidity();

                this.formInscripcion.get('ciudad').setValidators(this.evento.solicitar_ciudad === 'ACTIVADO' ? [Validators.required] : []);
                this.formInscripcion.get('ciudad').updateValueAndValidity();

                break;
            case 'passport':
                this.formInscripcion.get('rut').setValidators([Validators.required]);
                this.formInscripcion.get('rut').updateValueAndValidity();

                this.formInscripcion.get('ciudad').setValidators([]);
                this.formInscripcion.get('ciudad').updateValueAndValidity();

                break;
        }
    }

    getTipoDocumento2() {
        return this.formInscripcionPersona2.get('typeOfDocument').value;
    }

    changeTypeDocument2() {

        switch (this.getTipoDocumento2()) {
            case 'RUT':
                const validator: any[] = [Validators.required, this.rutValidator, Validators.minLength(5)];
                this.formInscripcionPersona2.get('rut').setValidators(validator);
                this.formInscripcionPersona2.get('rut').updateValueAndValidity();

                this.formInscripcionPersona2.get('ciudad').setValidators(this.evento.solicitar_ciudad === 'ACTIVADO' ? [Validators.required] : []);
                this.formInscripcionPersona2.get('ciudad').updateValueAndValidity();

                break;
            case 'passport':
                this.formInscripcionPersona2.get('rut').setValidators([Validators.required]);
                this.formInscripcionPersona2.get('rut').updateValueAndValidity();

                this.formInscripcionPersona2.get('ciudad').setValidators([]);
                this.formInscripcionPersona2.get('ciudad').updateValueAndValidity();

                break;
        }
    }

    changeExtranjero1(esExtranjero: boolean) {
        this.extranjero1 = esExtranjero;
        this.formInscripcion.get('typeOfDocument').setValue(esExtranjero ? 'passport' : 'RUT');
        this.changeTypeDocument1();
    }

    changeExtranjero2(esExtranjero: boolean) {
        this.extranjero2 = esExtranjero;
        this.formInscripcionPersona2.get('typeOfDocument').setValue(esExtranjero ? 'passport' : 'RUT');
        this.changeTypeDocument2();
    }

    selectAdicional(adicional) {
        this.adicionalesSeleccionados.push({
            adicionalId: adicional.id,
            eventoId: this.id,
            validado: false,
            id: Date.now()
        });
    }

    confirmarAdicional(form: any) {
        const adicionalSeleccionado = this.adicionalesSeleccionados.find((adicional) => adicional.id === form.id);
        adicionalSeleccionado.validado = true;
        adicionalSeleccionado.formAdicional = form;
    }

    obtenerValidado(id: number) {
        return this.adicionalesSeleccionados.find((adicional) => adicional.id === id).validado === true;
    }

    obtenerAdicionalValidado() {
        return this.adicionalesSeleccionados.filter((adicional) => adicional.validado === true);
    }

    getAdicionalTexto(form) {
        let texto = '';
        Object.entries(form).forEach(([key, value]) => {
            if (key !== 'id' && key !== 'porcentaje_cobro' && key !== 'eventoAdicional' && key !== 'campos_formulario') {
                texto = texto + ('<b>' + key.replace('_', ' ') + '</b>: ' + value + ' - ');
            }

            if (key === 'campos_formulario') {
                Object.entries(value).forEach(([key2, value2]) => {
                    texto = texto + ('<b>' + key2.replace('_', ' ') + '</b>: ' + value2 + ' - ');
                });
            }

        });

        return texto;
    }

    eliminar(id: number) {
        this.adicionalesSeleccionados = this.adicionalesSeleccionados.filter((adicional) => adicional.id !== id);
    }

    obtenerUltimaConfiguracionCat(cat: any) {

        const objetosFiltrados = cat.configuracionCategorias;
        const objetosOrdenados = objetosFiltrados.sort((a, b) => b.id - a.id);
        const ultimoObjeto = objetosOrdenados[0];
        return ultimoObjeto;

    }


}
