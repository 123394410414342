<app-navbar-style-two></app-navbar-style-two>



<div class="banner-area-two">
    <div class="container">
        <div class="banner-content">
            <h1>Explora, Compite, Conéctate</h1>
            <p>Encuentra eventos de ciclismo y participa en experiencias inolvidables.</p>
            <div class="search-box">

                <div class="form-search">
                    <div class="form-inner">
                        <div class="form-group search-info">
                            <!-- <i class="fa fa-sticky-note  bficon"></i> -->
                            <i class='bx bxs-check-square'></i>
                            <input type="text" [(ngModel)]="nombre" class="form-control" placeholder="Nombre">
                        </div>
                        <div class="form-group search-location">
                            <!-- <i class="fas fa-map-marker-alt"></i> -->
                            <i class='bx bxs-map'></i>
                            <input type="text" [(ngModel)]="lugar" class="form-control" placeholder="Lugar">
                            <!-- <a class="current-loc-icon" href="javascript:void(0);"><i class="fas fa-crosshairs"></i></a> -->
                        </div>
                        <button (click)="buscar()" class="btn btn-primary search-btn mt-0"><i class="fas fa-search"></i>
                            Buscar</button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>


<section class="collection-area pt-100 pb-100" style="overflow: hidden;">
    <div class="container">
        <div class="section-title">
            <h2>Encuentra tu evento</h2>
        </div>

        <div class="sorting-menu">
            <ul>
                <li #todos [ngClass]="filtro === 'TODAS' ? 'active' : ''" class="filter"
                    (click)="filtrarEventos('TODAS')">Todos</li>
                <li #por_realizar [ngClass]="filtro === 'POR_REALIZAR' ? 'active' : ''" class="filter por_realizar"
                    (click)="filtrarEventos('POR_REALIZAR')">Por Realizar</li>
                <li #realizados [ngClass]="filtro === 'REALIZADO' ? 'active' : ''" class="filter"
                    (click)="filtrarEventos('REALIZADO')">Finalizados</li>
                <li #ciclismo [ngClass]="filtro === 'bike' ? 'active' : ''" class="filter"
                    (click)="filtrarEventos('bike')">Ciclismo</li>
                <li #running [ngClass]="filtro === 'race' ? 'active' : ''" class="filter"
                    (click)="filtrarEventos('race')">Trail-Running</li>

            </ul>
        </div>

        <app-shimmer-large *ngIf="loading"></app-shimmer-large>
        <app-evento-item *ngIf="!loading" [eventosFiltro]="eventosFiltro"></app-evento-item>
        <br>
        <div class="more-collection">
            <a routerLink="/eventos">Ver más</a>
        </div>
    </div>
</section>

<section class="chef-area" style="padding: 0px 0px 20px 0px;" *ngIf="!loading">
    <div class="container">
        <app-shimmer-large *ngIf="loading"></app-shimmer-large>

        <app-mapa-chile *ngIf="!loading" [data]="this.corredoresPorRegion" style="display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;"></app-mapa-chile>
    </div>
</section>

<section class="services-area">
    <div class="container">
        <div class="section-title">
            <ng-adsense [adClient]="getEnvironmentAdSense()" [adSlot]="4514379323"></ng-adsense>
        </div>
    </div>
</section>

<!-- APP MOVIL -->
<section class="download-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="download-content">
                    <div class="section-title">
                        <span class="sub-title">Descarga</span>
                        <h2>Descarga nuestra aplicación móvil. Inscribirse a un evento nunca habia sido tan fácil</h2>
                        <p>Información de los eventos, inscripciones, podios, cambios de categorías y más.</p>
                    </div>

                    <ul>
                        <li style="text-align: center;"><span>01</span> Busca tu evento favorito</li>
                        <li style="text-align: center;"><span>02</span> Ve la información del evento</li>
                        <li style="text-align: center;"><span>03</span> Inscríbete!</li>
                    </ul>

                    <div class="app-wrap" style="text-align: center;">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=cl.bikerace"><img
                                src="assets/img/home-one/google-store.png" alt="Google"></a>
                        <a (click)="clickAppStore()"><img src="assets/img/home-one/app-store.png" alt="App"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-img">
                    <img src="assets/img/home-one/telefono.png" alt="Download">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- FORMULARIO COTIZACION -->
<div class="contact-form-area ptb-100">
    <div class="container">

        <div class="section-title">
            <h2 style="color:white">¿Quieres una cotización?</h2>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="contact-item">
                    <form id="contactForm" [formGroup]="formCotizacion">
                        <div class="row">
                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="nombre" name="name" id="name"
                                        class="form-control" required placeholder="Nombre">
                                    <span
                                        *ngIf="formCotizacion.controls['nombre'].invalid && (formCotizacion.controls['nombre'].dirty || formCotizacion.controls['nombre'].touched)"
                                        style="color:red"><b>El campo nombre es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="email" formControlName="correoElectronico" name="email" id="email"
                                        class="form-control" required placeholder="Correo electrónico">
                                    <span
                                        *ngIf="formCotizacion.controls['correoElectronico'].invalid && (formCotizacion.controls['correoElectronico'].dirty || formCotizacion.controls['correoElectronico'].touched)"
                                        style="color:red"><b>El campo correo electrónico es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="numeroTelefono" name="phone_number"
                                        id="phone_number" required class="form-control"
                                        placeholder="Número de teléfono">
                                    <span
                                        *ngIf="formCotizacion.controls['numeroTelefono'].invalid && (formCotizacion.controls['numeroTelefono'].dirty || formCotizacion.controls['numeroTelefono'].touched)"
                                        style="color:red"><b>El campo número de teléfono es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="number" formControlName="numeroDeportistas" name="cantidad_corredores"
                                        id="cantidad_corredores" required class="form-control"
                                        placeholder="Número aprox. de deportistas">
                                    <span
                                        *ngIf="formCotizacion.controls['numeroDeportistas'].invalid && (formCotizacion.controls['numeroDeportistas'].dirty || formCotizacion.controls['numeroDeportistas'].touched)"
                                        style="color:red"><b>El campo número de deportistas es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="lugarEvento" name="lugar" id="lugar"
                                        class="form-control" required placeholder="Lugar realización del evento">
                                    <span
                                        *ngIf="formCotizacion.controls['lugarEvento'].invalid && (formCotizacion.controls['lugarEvento'].dirty || formCotizacion.controls['lugarEvento'].touched)"
                                        style="color:red"><b>El campo lugar del evento es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="fechaEvento" name="fecha" id="fecha"
                                        class="form-control" required placeholder="Fecha estimada del evento">
                                    <span
                                        *ngIf="formCotizacion.controls['fechaEvento'].invalid && (formCotizacion.controls['fechaEvento'].dirty || formCotizacion.controls['fechaEvento'].touched)"
                                        style="color:red"><b>El campo fecha del evento es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" formControlName="comentarios" class="form-control"
                                        id="message" cols="30" rows="6" required placeholder="Comentario"></textarea>
                                    <span
                                        *ngIf="formCotizacion.controls['comentarios'].invalid && (formCotizacion.controls['comentarios'].dirty || formCotizacion.controls['comentarios'].touched)"
                                        style="color:red"><b>El campo comentarios es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12" style="text-align: -webkit-center;">
                                <div class="form-group">
                                    <re-captcha (resolved)="checkCaptcha($event)" formControlName="token"
                                        id="recaptcha"></re-captcha>
                                    <span
                                        *ngIf="formCotizacion.controls['token'].invalid && (formCotizacion.controls['token'].dirty || formCotizacion.controls['token'].touched)"
                                        style="color:red"><b>Este campo es obligatorio.</b></span>


                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12" style="text-align: center;">
                                <button type="button" (click)="solicitarCotizacion()" class="cmn-btn btn">Solicitar
                                    cotización</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

            <div class="col-lg-6">
                <div class="contact-img">
                    <img src="assets/img/manuelfelipe.png" alt="Contact">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- TOP MASCULINOS -->
<section class="chef-area" style="padding-top: 30px;" *ngIf="topmasculino.length>0">
    <div class="container">
        <div class="section-title">
            <h2>Deportistas TOP Masculinos</h2>
            <p>De acuerdo de los resultados cronometrados y subidos a la plataforma Bikerace, los deportistas top
                masculinos son los siguientes:</p>
        </div>

        <app-shimmer-medium *ngIf="loading"></app-shimmer-medium>

        <div class="top-masculinos-slider owl-theme owl-carousel">
                <div class="chef-item" *ngFor="let corredor of topmasculino;let i = index">
                    <div class="chef-top">
                        <div class="chef-inner-top">
                            <h3>{{i+1}}</h3>
                            
                        </div>
                        <img *ngIf="corredor.photoProfile === null"
                            style="height: 306px; object-fit: cover"
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-prod.appspot.com/o/147144.png?alt=media&token=34eed580-0d28-4e7b-89e4-1a9fdd7b0612"
                            alt="Chef">
                        <img *ngIf="corredor.photoProfile !== null"
                            style="height: 306px; object-fit: cover" [src]="corredor.photoProfile"
                            alt="Chef">
                        <div class="chef-inner">
                            <h3>{{camelCaseToWords(corredor.nombre)}}</h3>
                            <span>{{camelCaseToWords(corredor.apellido)}}</span>
                        </div>
                    </div>
                    <div class="chef-bottom">

                    </div>
                </div>
        </div>
        
    </div>
</section>

<!-- TOP FEMENINOS -->
<section class="chef-area" style="padding-top: 30px;" *ngIf="topfemenino.length>0">
    <div class="container">
        <div class="section-title">
            <h2>Deportistas TOP Femeninas</h2>
            <p>De acuerdo de los resultados cronometrados y subidos a la plataforma Bikerace, las deportistas top
                femeninas son las siguientes:</p>
        </div>
        <app-shimmer-medium *ngIf="loading"></app-shimmer-medium>

        <div class="top-femeninos-slider owl-theme owl-carousel">
            <div class="chef-item" *ngFor="let corredor of topfemenino;let i = index">
                <div class="chef-top">
                    <div class="chef-inner-top">
                        <h3>{{i+1}}</h3>
                        
                    </div>
                    <img *ngIf="corredor.photoProfile === null"
                        style="height: 306px; object-fit: cover"
                        src="https://firebasestorage.googleapis.com/v0/b/bikerace-prod.appspot.com/o/147144.png?alt=media&token=34eed580-0d28-4e7b-89e4-1a9fdd7b0612"
                        alt="Chef">
                    <img *ngIf="corredor.photoProfile !== null"
                        style="height: 306px; object-fit: cover" [src]="corredor.photoProfile"
                        alt="Chef">
                    <div class="chef-inner">
                        <h3>{{camelCaseToWords(corredor.nombre)}}</h3>
                        <span>{{camelCaseToWords(corredor.apellido)}}</span>
                    </div>
                </div>
                <div class="chef-bottom">

                </div>
            </div>
    </div>
    </div>
</section>

<!-- TESTIMONIOS -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>¿Qué dicen acerca de Bikerace?</h2>
        </div>

        <div class="services-slider owl-theme owl-carousel">
            <!--            <div class="services-item">-->
            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/testimonios/6.jpeg" alt="Blog">
                    <!--                        </a>-->
                    <span>2025</span>
                </div>
                <div class="blog-bottom">
                    <!--                    <h3>Francisco Balboa Mariangel - Presidente Deferación de Ciclismo Chillán</h3>-->
                    <p>"Un evento de gran envergadura, necesita que todos sus engranajes funcionen bien; algunos con un
                        estilo rudo, pero ameno. Cuando decidimos darle un giro al Rally Kingkona, después de 10 años
                        realizándose, tuvimos reuniones por Zoom con el staff de Bikerace; y recordamos que hace unos
                        años atrás ya nos habían hecho una propuesta de trabajo.
                        Encontramos que tenían un estilo estricto, propio, también flexible y justo lo que
                        necesitábamos. Aceptaron nuestras propuestas, y tomamos las suyas. Se tomaron la molestia de
                        venir días antes a conocer el lugar, y el sector donde se ubicaría el check de la meta montaña.
                        Entrega de kits sin problemas, incluso ellos pegaron los chips a los números. No hubo cambios de
                        categorías, ni desafío ni inscripciones fuera del plazo dado por ellos. Eso nos dió confianza
                        que no habría errores (a excepción de ciertas mañas que aún están en el MTB).
                        Saludamos y damos las gracias a la “Comandante” Vivian y todo su pelotón que se lució en la
                        versión 10+1 del Rally Kingkona MTB 2025, el inicio de una asociatividad que perdure en el
                        tiempo. Tienen una dedicación especial a su trabajo, lo quieren y lo cuidan."</p>
                </div>
            </div>
            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/testimonios/1.png" alt="Blog">
                    <!--                        </a>-->
                    <span>2024</span>
                </div>
                <div class="blog-bottom">
                    <!--                    <h3>Francisco Balboa Mariangel - Presidente Deferación de Ciclismo Chillán</h3>-->
                    <p>"En primer lugar saludar y agradecer enormemente al Gran equipo de BIKERACE, dónde la forma
                        amable y personalizada, con la que ellos trabajan, nos hizo más amena la organización de cada
                        una de las actividades, siempre disponibles a ayudar y salir de nuestras dudas... Ya sabemos
                        donde acudir para nuestros eventos ♥️"</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/testimonios/2.png" alt="Blog">
                    <!--                        </a>-->
                    <span>20 Octubre 2024</span>
                </div>
                <div class="blog-bottom">
                    <!--                    <h3>Francisco Balboa Mariangel - Presidente Deferación de Ciclismo Chillán</h3>-->
                    <p>"Quiero expresar mi más sincero agradecimiento a BIKERACE, empresa líder en cronometraje de
                        bicicletas, por su excepcional servicio y dedicación. Su compromiso con la precisión y la
                        innovación tecnológica ha sido fundamental en nuestras dos versiones RALLY LOS CRISTALES.
                        Su equipo demostró una gran responsabilidad y eficiencia en cada momento, brindándome confianza
                        y seguridad en los resultados.
                        Muchas gracias por su apoyo y colaboración. Recomiendo BIKERACE a todos que buscan precisión y
                        profesionalismo en sus eventos deportivos. ¡Gracias por ayudarnos a alcanzar nuestros
                        objetivos!".</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/testimonios/3.png" alt="Blog">
                    <!--                        </a>-->
                    <span>15 Diciembre 2024</span>
                </div>
                <div class="blog-bottom">
                    <!--                    <h3>Felipe González - Presidente Club de ciclismo Riders Ñuble</h3>-->
                    <p>"El contar con el servicio de Bikerace en nuestro 3er Desafío Ciclismo Zona 7 fue fundamental
                        para que resultara con éxito el evento. Desde el momento en que se les solicitó el servicio hubo
                        muy buena comunicación con el equipo Bikerace. Es una carga menos el no tener que preocuparse de
                        las inscripciones de manera directa todo a través de una plataforma simple de utilizar para el
                        corredor. El tener las placas de corredores asignadas antes del evento. La ayuda con los afiches
                        y difusión. Para el evento en sí contar con cronometraje electrónico hoy en día es
                        imprescindible, no hay nada más satisfactorio que el corredor una vez llegando a meta y recibida
                        su medalla finisher pueda ir a revisar su posición final en la carrera. Luego, poder recibir la
                        lista impresa de los ganadores es otra ventaja que agiliza la ceremonia de premiación y ayuda a
                        que se respete el cronograma del evento. Los competidores están acostumbrándose a ponerle
                        atención a que su inscripción esté 100% correcta y que no hay opción de modificaciones ni
                        inscripciones el día de carrera, eso hace que todo fluya mucho mejor. En resumen, sin Bikerace
                        el evento no habría sido lo mismo, esperamos seguir trabajando en conjunto en los próximos
                        evento que realicemos en Cauquenes con Club Ciclismo Zona 7".</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <img src="assets/img/testimonios/4.png" alt="Blog">
                    <span>12 Octubre 2024</span>
                </div>
                <div class="blog-bottom">
                    <p>“Excelente servicio del equipo de BIKERACE, en nuestra primera versión de rayo fest fue un
                        acierto contar con gente con este nivel de compromiso y profesionalismo. Era una propuesta
                        diferente y nuevo para todos y el trabajo en conjunto hizo de este evento una experiencia muy
                        enriquecedora, tanto para la organización como cada deportista que asistió, no dudaremos en
                        recomendar siempre a este tremendo equipo".</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <img src="assets/img/testimonios/5.png" alt="Blog">
                    <span>16 Noviembre 2024</span>
                </div>
                <div class="blog-bottom">
                    <p>"En la Gran Travesía de los Valles Ultra Trail Merrell GTVUT 2024, contar con los servicios de
                        Bike Race fue una experiencia excepcional. Su equipo demostró ser atento, amable y profundamente
                        comprometido con la calidad del evento. Destacan por su capacidad de gestión y resolución,
                        enfrentando cualquier desafío con autonomía y eficacia, siempre pensando en el éxito del evento
                        y en las necesidades de los organizadores.
                        La experiencia que tienen en eventos deportivos masivos se refleja en cada detalle: se anticipan
                        a posibles problemas, los corrigen antes de que escalen y, lo más importante, entienden las
                        dinámicas y demandas del organizador, actuando con rapidez y criterio incluso bajo presión.
                        Recomendamos ampliamente a Bike Race para cualquier evento deportivo. Su profesionalismo y
                        dedicación son insuperables.

                        Pedro Pereira Lara"</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <img src="assets/img/testimonios/8.png" alt="Blog">
                    <span>16 Noviembre 2024</span>
                </div>
                <div class="blog-bottom">
                    <p>"Bikerace es una plataforma que comencé usándola sin saber nada en la creación de eventos. Pero a
                        los pocos minutos me resultó muy fácil e intuitiva de utilizar. Es una plataforma que facilita
                        mucho el registro de corredores ya sea para eventos pagados o eventos recreativos gratuitos.
                        Sumado a esto, en cada ocasión que he necesitado ayuda para algo puntual, el personal de
                        bikerace me ha respondido a los pocos minutos de realizar la consulta"</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- AUSPICIADORES -->
<app-shimmer-medium *ngIf="loading"></app-shimmer-medium>
<section class="chef-area" style="padding-top: 30px;" *ngIf="!loading && auspiciadores.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Auspiciadores y Alianzas</h2>
            <!--            <p>Ofrecemos el mejor servicio, siempre comprometidos a que sea una excelente experiencia. Te invitamos a unirte a los mejores!!</p>-->
        </div>

        <div class="auspiciadores-slider owl-theme owl-carousel">
            <div class="chef-item" *ngFor="let auspiciador of auspiciadores">
                <div class="chef-top">
                    <img [src]="auspiciador.image_url" alt="Chef">
                    <div class="chef-inner">
                        <h3>{{auspiciador.titulo}}</h3>
                        <span>{{auspiciador.subtitulo}}</span>
                    </div>
                </div>
                <div class="chef-bottom">
                    <ul>
                        <li *ngIf="auspiciador.facebook_url !== null"><a target="_blank"
                                [href]="auspiciador.facebook_url"><i class='bx bxl-facebook'></i></a></li>
                        <li *ngIf="auspiciador.instagram_url !== null"><a target="_blank"
                                [href]="auspiciador.instagram_url"><i class='bx bxl-instagram'></i></a></li>
                        <li *ngIf="auspiciador.pagina_web_url !== null"><a target="_blank"
                                [href]="auspiciador.pagina_web_url"><i class='bx bx-laptop'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>


    </div>
</section>

<!-- CLIENTES -->
<app-shimmer-medium *ngIf="loading"></app-shimmer-medium>
<section class="chef-area" style="padding-top: 30px;" *ngIf="!loading && clientes.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Clientes</h2>
            <p>Ofrecemos el mejor servicio, siempre comprometidos a que sea una excelente experiencia. Te invitamos a
                unirte a los mejores!!</p>
        </div>

        <div class="clientes-slider owl-theme owl-carousel">
            <div class="chef-item" *ngFor="let cliente of clientes">
                <div class="chef-top">
                    <img [src]="cliente.image_url" alt="Chef">
                    <div class="chef-inner">
                        <h3>{{cliente.titulo}}</h3>
                        <span>{{cliente.subtitulo}}</span>
                    </div>
                </div>
                <div class="chef-bottom">
                    <ul>
                        <li *ngIf="cliente.facebook_url !== null"><a target="_blank" [href]="cliente.facebook_url"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li *ngIf="cliente.instagram_url !== null"><a target="_blank" [href]="cliente.instagram_url"><i
                                    class='bx bxl-instagram'></i></a></li>
                        <li *ngIf="cliente.pagina_web_url !== null"><a target="_blank"
                                [href]="cliente.pagina_web_url"><i class='bx bx-laptop'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</section>

<br>
<br>
<br>
<app-footer-style-two></app-footer-style-two>